<template>
  <div class="auth-layout">
    <div class="auth-layout__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style lang="scss">
.auth-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1000;

  @include phone {
    display: flex;
    align-items: center;
  }
}

.auth-layout__wrapper {
  width: 100%;
  padding: 5vh 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet {
    padding: 5px 10px;
  }

  @include phone {
    width: 100%;
  }
}

.auth-layout__logo {
  text-align: center;
  margin-bottom: 32px;
}
</style>
